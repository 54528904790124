import axios from "axios";
import { DesignCafeError } from "./DesignCafeError";
import Constants from "../resources/Constants";

export class ApiManager {
  constructor() {
    axios.defaults.baseURL = process.env.REACT_APP_PP_API_URL;
  }
  private parseBody(response: any) {
    const results = JSON.parse(response.body);
    if (results.statusCode === 200) {
      return results;
    }
    else if(results.statusCode === 409){
      return results.body
    }
     else {
      throw new Error(results.body.message);
    }
  }
  private processError(error: any): DesignCafeError {
    if (axios.isCancel(error)) {
      return new DesignCafeError("Cancelled", 499);
    } else {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        const message = error.response.data.message;
        const code = error.response.data.code;
        return new DesignCafeError(message, code);
      } else {
        if (error.data && error.data.message) {
          return new DesignCafeError(error.data.message, error.data.code);
        } else if (error.response) {
          return new DesignCafeError(error.message, error.response.status);
        } else {
          return new DesignCafeError(error.message, 400);
        }
      }
    }
  }

  private defaultHeaders(isAutoAuthenticateHeader?: boolean) {
    const headers: any = {};
    const loginToken = localStorage.getItem(Constants.LOGGED_IN_TOKEN);
    if (isAutoAuthenticateHeader) {
      headers["Authorization"] = loginToken;
    } else {
      if (loginToken !== undefined) {
        headers.token = loginToken;
      }
    }
    headers["Content-Type"] = "application/json";
    return headers;
  }

  public async generateOtp(data: any): Promise<any> {
    try {
      const response = await axios.post("/send-otp", data);
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }

  public async verifyOtp(data: any): Promise<any> {
    try {
      const response = await axios.post("/verify-otp", data);
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }

  public async autoAuthenticate(): Promise<any> {
    try {
      const headers = this.defaultHeaders(true);
      const response = await axios.post("/auto-authenticate", null, {
        headers,
      });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getManagerAndRegion(): Promise<any> {
    try {
      const response = await axios.get("/pickList");
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getProfile(): Promise<any> {
    try {
      const headers = this.defaultHeaders(true);
      const response = await axios.get("/profile", { headers });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }

  public async acceptTerms(data: any): Promise<any> {
    try {
      const response = await axios.post("/acceptTerms", data);
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getTerms(
    name?: string,
    address?: string,
    panNumber?: string,
    gstId?: string
  ): Promise<any> {
    try {
      let headers: any = {};
      let params: any = {};

      if (name != null && name.length > 0) {
        params.name = name;
      }

      if (address != null && address.length > 0) {
        params.address = address;
      }

      if (panNumber != null && panNumber.length > 0) {
        params.panNumber = panNumber;
      }

      if (gstId != null && gstId.length > 0) {
        params.gstId = gstId;
      }

      const response = await axios.get("/terms", { headers, params });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }

  public async registerCustomer(data: object) {
    try {
      const response = await axios.post("/signUp", data);
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getLeads(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
    searchOrder: boolean,
    filterKey: string,
    leadfilterKey: string,
    fromDate: string,
    toDate: string
  ) {
    try {
      const headers = this.defaultHeaders(true);
      let params: any = {};
      if (fromDate != null && fromDate.length > 0) {
        params.fromDate = fromDate;
      }
      if (toDate != null && toDate.length > 0) {
        params.toDate = toDate;
      }

      if (pageIndex != null) {
        params.page = pageIndex;
      }

      if (pageSize != null) {
        params.limit = pageSize;
      }

      if (searchKey != null && searchKey.length > 0) {
        params.sortingKey = searchKey;
      }
      if (searchOrder) {
        params.sortingPriority = "ASC";
      } else {
        params.sortingPriority = "DESC";
      }
      if (filterKey != null && filterKey.length > 0) {
        params.filterKey = filterKey;
      }
      if (leadfilterKey != null && leadfilterKey.length > 0) {
        params.leadfilterKey = leadfilterKey;
      }
      const response = await axios.get("/lead", { headers, params });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getProjects(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
    searchOrder: boolean,
    filterKey: string,
    fromDate: string,
    toDate: string
  ) {
    try {
      const headers = this.defaultHeaders(true);
      let params: any = {};
      if (fromDate != null && fromDate.length > 0) {
        params.fromDate = fromDate;
      }
      if (toDate != null && toDate.length > 0) {
        params.toDate = toDate;
      }
      if (pageIndex != null) {
        params.page = pageIndex;
      }

      if (pageSize != null) {
        params.limit = pageSize;
      }

      if (searchKey != null && searchKey.length > 0) {
        params.sortingKey = searchKey;
      }
      if (searchOrder) {
        params.sortingPriority = "ASC";
      } else {
        params.sortingPriority = "DESC";
      }
      if (filterKey != null && filterKey.length > 0) {
        params.filterKey = filterKey;
      }
      const response = await axios.get("/project", { headers, params });
      console.log("Projects", this.parseBody(response.data));
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getOpportunity(
    pageIndex: number,
    pageSize: number,
    searchKey: string,
    searchOrder: boolean,
    filterKey: string,
    fromDate: string,
    toDate: string
  ) {
    try {
      const headers = this.defaultHeaders(true);
      let params: any = {};
      if (fromDate != null && fromDate.length > 0) {
        params.fromDate = fromDate;
      }
      if (toDate != null && toDate.length > 0) {
        params.toDate = toDate;
      }

      if (pageIndex != null) {
        params.page = pageIndex;
      }

      if (pageSize != null) {
        params.limit = pageSize;
      }

      if (searchKey != null && searchKey.length > 0) {
        params.sortingKey = searchKey;
      }
      if (searchOrder) {
        params.sortingPriority = "ASC";
      } else {
        params.sortingPriority = "DESC";
      }
      if (filterKey != null && filterKey.length > 0) {
        params.filterKey = filterKey;
      }
      const response = await axios.get("/opportunity", { headers, params });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async submitLead(data: object) {
    try {
      const headers = this.defaultHeaders(true);
      const response = await axios.post("/lead", data, { headers });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getDashboardData(fromDate: string, toDate: string) {
    try {
      const headers = this.defaultHeaders(true);
      let params: any = {};

      if (fromDate != null && fromDate.length > 0) {
        params.fromDate = fromDate;
      }
      if (toDate != null && toDate.length > 0) {
        params.toDate = toDate;
      }
      const response = await axios.get("/dashboardData", { headers, params });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getFaqs(searchKey: string) {
    try {
      const headers = this.defaultHeaders(true);
      let params: any = {};
      if (searchKey != null && searchKey.length > 0) {
        params.searchKey = searchKey;
      }
      const response = await axios.get("/faqs", { headers, params });
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
  public async getSearchResults(
    searchKey: string,
    leadPage: number,
    opportunityPage: number,
    projectPage: number,
    pageSize: number
  ) {
    try {
      const headers = this.defaultHeaders(true);
      let params: any = {};
      if (searchKey != null && searchKey.length > 0) {
        params.searchKey = searchKey;
      }
      if (leadPage != null) {
        params.leadPage = leadPage;
      }
      if (opportunityPage != null) {
        params.opportunityPage = opportunityPage;
      }
      if (projectPage != null) {
        params.projectPage = projectPage;
      }
      if (pageSize != null) {
        params.limit = pageSize;
      }
      const response = await axios.get("/search", { headers, params });
      console.log(366, response);
      return this.parseBody(response.data);
    } catch (error) {
      const processError = this.processError(error);
      if (processError) {
        throw processError;
      }
    }
  }
}
