import React from "react";
import { ComponentBase } from "resub";
import {
  ISearchState,
  SearchViewModel,
} from "../../../view-model/SearchViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ImageAssets from "../../../assets/ImageAssets";
import "./Search.css";
import "../../../CSS/Main.css";
import { isNullOrUndefined } from "util";
import { TablePagination } from "@material-ui/core";

export default class Search extends ComponentBase<any, ISearchState> {
  private viewModel: SearchViewModel;

  constructor(props: any) {
    super(props);
    this.viewModel = DependencyInjector.default().provideSearchViewModel();
  }

  componentDidMount(): void {
    this.viewModel.load();
  }

  render() {
    console.log(this.state.leads);
    const leadsTableBodyinfo: any = [];
    if (!isNullOrUndefined(this.state.leads) && this.state.leads.length > 0) {
      let index = 0;
      for (let lead of this.state.leads) {
        index++;
        leadsTableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="center">
              {lead.name}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.leadStatus}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.email}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.city}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.leadStage}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.phoneNumber}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.createdDate}
            </TableCell>
          </TableRow>
        );
      }
    }
    const projectsTableBodyinfo: any = [];
    if (
      !isNullOrUndefined(this.state.projects) &&
      this.state.projects.length > 0
    ) {
      let index = 0;
      for (let project of this.state.projects) {
        index++;
        let statusClass = "projectStatus";
        let milestoneClass = "projectStage";
        if (project.status === "On Hold") {
          statusClass = "projectStatusOnHold";
        }
        if (project.milestone === "Factory Dispatch") {
          milestoneClass = "projectStageDispatch";
        }

        projectsTableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="justify">
              {project.name}
            </TableCell>
            <TableCell align="justify">
              <div className={milestoneClass}>{project.milestone}</div>
            </TableCell>
            <TableCell align="justify">
              <div className={statusClass}>{project.status}</div>
            </TableCell>
            <TableCell align="justify" className="userDetail">
              {project.amount}
            </TableCell>
            <TableCell align="justify" className="userDetail">
              {project.startDate}
            </TableCell>
            <TableCell align="justify" className="userDetail">
              {project.endDate}
            </TableCell>
          </TableRow>
        );
      }
    }
    const opportunityTableBodyinfo: any = [];
    if (
      !isNullOrUndefined(this.state.opportunities) &&
      this.state.opportunities.length > 0
    ) {
      let index = 0;
      for (let opportunity of this.state.opportunities) {
        index++;
        opportunityTableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="justify">
              {opportunity.name}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.stageName}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.amount}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.region}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.closeDate}
            </TableCell>
            <TableCell className="userDetail" align="justify">
              {opportunity.createdDate}
            </TableCell>
          </TableRow>
        );
      }
    }
    return (
      <div>
        {console.log(141, this.state.leads)}
        {this.state.leads && this.state.leads.length > 0 ? (
          <div className="pt-4">
            <div className="searchHeadTextWrap">
              <p className="searchHeadText pt-3 pl-3">Leads</p>
            </div>
            <Paper>
              <TableContainer className="paper" component={Paper}>
                <Table className="" stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="name"
                      >
                        Name
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="leadStatus"
                      >
                        Lead Status
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="email"
                      >
                        Email
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="city"
                      >
                        City
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="leadStage"
                      >
                        Lead Stage
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="phoneNumber"
                      >
                        Mobile
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        variant="head"
                        align="center"
                        id="createdDate"
                      >
                        Created Date
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{leadsTableBodyinfo}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows/page"
                count={this.state.LeadsTotalCount}
                rowsPerPage={this.state.pageSize}
                page={this.state.leadPage}
                onChangePage={(event, pageIndex) => {
                  this.viewModel.set("leadPage", pageIndex);
                  this.viewModel.load();
                }}
                onChangeRowsPerPage={(event) => {
                  console.log("pageSize", event.target.value);
                  this.viewModel.set("pageSize", event.target.value);
                  this.viewModel.load();
                }}
              />
            </Paper>
          </div>
        ) : null}
        {this.state.opportunities && this.state.opportunities.length > 0 ? (
          <div className="pt-4">
            <div className="searchHeadTextWrap">
              <p className="searchHeadText pt-3 pl-3">Opportunities</p>
            </div>
            <Paper>
              <TableContainer className="paper" component={Paper}>
                <Table className="" stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="userHead" align="justify" id="name">
                        Opportunity Name{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="stageName"
                      >
                        Opportunity Stage{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="amount"
                      >
                        Amount{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="region"
                      >
                        Region{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="closeDate"
                      >
                        Close Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="createdDate"
                      >
                        Created Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{opportunityTableBodyinfo}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows/page"
                count={this.state.OpportunitiesTotalCount}
                rowsPerPage={this.state.pageSize}
                page={this.state.opportunityPage}
                onChangePage={(event, pageIndex) => {
                  this.viewModel.set("opportunityPage", pageIndex);
                  this.viewModel.load();
                }}
                onChangeRowsPerPage={(event) => {
                  console.log("pageSize", event.target.value);
                  this.viewModel.set("pageSize", event.target.value);
                  this.viewModel.load();
                }}
              />
            </Paper>
          </div>
        ) : null}
        {this.state.projects && this.state.projects.length > 0 ? (
          <div className="pt-4">
            <div className="searchHeadTextWrap">
              <p className="searchHeadText pt-3 pl-3">Projects</p>
            </div>
            <Paper>
              <TableContainer className="paper" component={Paper}>
                <Table className="paper" stickyHeader aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="userHead" align="justify" id="name">
                        Project Name{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="milestone"
                      >
                        Project stage{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="status"
                      >
                        Status{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="amount"
                      >
                        Amount{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="startDate"
                      >
                        Start Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                      <TableCell
                        className="userHead"
                        align="justify"
                        id="endDate"
                      >
                        Tentative End Date{" "}
                        <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{projectsTableBodyinfo}</TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                labelRowsPerPage="Rows/page"
                count={this.state.ProjectsTotalCount}
                rowsPerPage={this.state.pageSize}
                page={this.state.projectPage}
                onChangePage={(event, pageIndex) => {
                  this.viewModel.set("projectPage", pageIndex);
                  this.viewModel.load();
                }}
                onChangeRowsPerPage={(event) => {
                  console.log("pageSize", event.target.value);
                  this.viewModel.set("pageSize", event.target.value);
                  this.viewModel.load();
                }}
              />
            </Paper>
          </div>
        ) : null}
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
