import { BaseState } from "../view-model/BaseState";
import { UserRepository } from "../domain/repository/userRepository";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { ValidationUtils } from "../core/ValidationUtils";
import { isNullOrUndefined } from "util";
import { LoginViewModel } from "../view-model/LoginViewModel";
import { ApiManager } from "../api/ApiManager";
import { RegisterViewModel } from "../view-model/RegisterViewModel";
import { LeadViewModel } from "../view-model/LeadViewModel";
import { ProjectViewModel } from "../view-model/ProjectViewModel";
import { OpportunityViewModel } from "../view-model/OpportunityViewModel";
import { SubmitLeadViewModel } from "../view-model/SubmitLeadViewModel";
import { ProfileViewModel } from "../view-model/ProfileViewModel";
import { DashboardViewModel } from "../view-model/DashboardViewModel";
import { FaqViewModel } from "../view-model/FaqViewModel";
import { SearchViewModel } from "../view-model/SearchViewModel";
import { HomeViewModel } from "../view-model/HomeViewModel";

export class DependencyInjector {
  private static dependencyInjector: DependencyInjector;
  private baseState: BaseState;
  private userRepository: UserRepository;
  private ValidationUtils: ValidationUtils;
  private apiManager: ApiManager;
  private loginViewModel: LoginViewModel | undefined;
  private registerViewModel: RegisterViewModel | undefined;
  private leadViewModel: LeadViewModel | undefined;
  private projectViewModel: ProjectViewModel | undefined;
  private opportunityViewModel: OpportunityViewModel | undefined;
  private submitLeadViewModel: SubmitLeadViewModel | undefined;
  private profileViewModel: ProfileViewModel | undefined;
  private dashboardRepository: DashboardRepository;
  private dashboardViewModel: DashboardViewModel | undefined;
  private faqViewModel: FaqViewModel | undefined;
  private searchViewModel: SearchViewModel | undefined;
  private homeViewModel: HomeViewModel | undefined;

  constructor() {
    this.apiManager = new ApiManager();
    this.userRepository = new UserRepository(this.apiManager);
    this.dashboardRepository = new DashboardRepository(this.apiManager);
    this.baseState = new BaseState(this.userRepository);
    this.ValidationUtils = new ValidationUtils();
  }

  provideBaseState(): BaseState {
    return this.baseState;
  }

  public static initialize(): void {
    if (isNullOrUndefined(DependencyInjector.dependencyInjector)) {
      DependencyInjector.dependencyInjector = new DependencyInjector();
    }
  }

  public static default(): DependencyInjector {
    if (isNullOrUndefined(DependencyInjector.dependencyInjector)) {
      DependencyInjector.dependencyInjector = new DependencyInjector();
    }
    return this.dependencyInjector;
  }

  provideLoginViewModel(): LoginViewModel {
    if (isNullOrUndefined(this.loginViewModel)) {
      this.loginViewModel = new LoginViewModel(
        this.baseState,
        this.userRepository,
        this.ValidationUtils
      );
    }
    return this.loginViewModel;
  }

  provideRegisterViewModel(): RegisterViewModel {
    if (isNullOrUndefined(this.registerViewModel)) {
      this.registerViewModel = new RegisterViewModel(
        this.baseState,
        this.userRepository,
        this.ValidationUtils
      );
    }
    return this.registerViewModel;
  }
  provideLeadViewModel(): LeadViewModel {
    if (isNullOrUndefined(this.leadViewModel)) {
      this.leadViewModel = new LeadViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.leadViewModel;
  }
  provideProjectViewModel(): ProjectViewModel {
    if (isNullOrUndefined(this.projectViewModel)) {
      this.projectViewModel = new ProjectViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.projectViewModel;
  }
  provideOpportunityViewModel(): OpportunityViewModel {
    if (isNullOrUndefined(this.opportunityViewModel)) {
      this.opportunityViewModel = new OpportunityViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.opportunityViewModel;
  }
  provideSubmitLeadViewModel(): SubmitLeadViewModel {
    if (isNullOrUndefined(this.submitLeadViewModel)) {
      this.submitLeadViewModel = new SubmitLeadViewModel(
        this.baseState,
        this.dashboardRepository,
        this.userRepository,
        this.ValidationUtils
      );
    }
    return this.submitLeadViewModel;
  }
  provideProfileViewModel(): ProfileViewModel {
    if (isNullOrUndefined(this.profileViewModel)) {
      this.profileViewModel = new ProfileViewModel(
        this.baseState,
        this.dashboardRepository,
        this.userRepository,
        this.ValidationUtils
      );
    }
    return this.profileViewModel;
  }
  provideDashboardViewModel(): DashboardViewModel {
    if (isNullOrUndefined(this.dashboardViewModel)) {
      this.dashboardViewModel = new DashboardViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.dashboardViewModel;
  }
  provideFaqViewModel(): FaqViewModel {
    if (isNullOrUndefined(this.faqViewModel)) {
      this.faqViewModel = new FaqViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.faqViewModel;
  }
  provideSearchViewModel(): SearchViewModel {
    if (isNullOrUndefined(this.searchViewModel)) {
      this.searchViewModel = new SearchViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.searchViewModel;
  }

  provideHomeViewModel(): HomeViewModel {
    if (isNullOrUndefined(this.homeViewModel)) {
      this.homeViewModel = new HomeViewModel(
        this.baseState,
        this.dashboardRepository
      );
    }
    return this.homeViewModel;
  }
}
