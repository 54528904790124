import { BaseViewModel } from "./BaseViewModel";
import { BaseState } from "./BaseState";
import { DashboardRepository } from "../domain/repository/DashboardRepository";
import { OpportunityInfo } from "../domain/model/OpportunityInfo";
import { LeadsInfo } from "../domain/model/LeadsInfo";
import { ProjectInfo } from "../domain/model/ProjectInfo";
import moment from "moment";
import { ValidationUtils } from "../core/ValidationUtils";
export interface ISearchState {
  isLoading: boolean;
  LeadsTotalCount: number;
  ProjectsTotalCount: number;
  OpportunitiesTotalCount: number;
  pageSize: number;
  leadPage: number;
  opportunityPage: number;
  projectPage: number;
  opportunities: OpportunityInfo[];
  leads: LeadsInfo[];
  projects: ProjectInfo[];
  filterKey: string;
  searchKey: string;
}

export interface ISearchProps {}

export class SearchViewModel extends BaseViewModel {
  protected state: ISearchState;
  constructor(
    private baseState: BaseState,
    private dashboardRepository: DashboardRepository
  ) {
    super();
    this.state = this.defaultState();
  }

  protected setState(newState: ISearchState) {
    this.state = newState;
    this.trigger();
  }

  defaultState(): ISearchState {
    return {
      isLoading: false,
      LeadsTotalCount: 0,
      ProjectsTotalCount: 0,
      OpportunitiesTotalCount: 0,
      pageSize: 25,
      leadPage: 0,
      opportunityPage: 0,
      projectPage: 0,
      opportunities: [],
      leads: [],
      projects: [],
      filterKey: "All",
      searchKey: "",
    };
  }

  public async load(): Promise<void> {
    try {
      const response = await this.dashboardRepository.getSearchResults(
        this.state.searchKey,
        this.state.leadPage,
        this.state.opportunityPage,
        this.state.projectPage,
        this.state.pageSize
      );
      console.log(6969, response.body.leads);
      response.body.leads.data.forEach((element: any) => {
        element.createdDate = moment(element.createdDate).format("D MMMM Y");
      });
      response.body.opprtunities.data.forEach((element: any) => {
        element.amount = ValidationUtils.convertToIndianStandardCurrency(
          "" + element.amount
        );
        element.createdDate = moment(element.createdDate).format("D MMMM Y");
        element.closeDate = moment(element.closeDate).format("D MMMM Y");
      });

      // response.body.projects.data.forEach((element: any) => {
      //   element.amount = ValidationUtils.convertToIndianStandardCurrency(
      //     "" + element.amount
      //   );
      //   element.startDate = moment(element.startDate).format("D MMMM Y");
      //   element.endDate = moment(element.endDate).format("D MMMM Y");
      // });

      console.log(8989, response.body.leads.data);
      this.setMany({
        ...this.state,
        opportunities: response.body.opprtunities.data,
        leads: response.body.leads.data,
        LeadsTotalCount: response.body.leads.totalCount,
        OpportunitiesTotalCount: response.body.opprtunities.totalCount,
      });
    } catch (error) {
      console.log(100, error);
      this.baseState.set("isLoading", false);
    }
  }
}
