import React from "react";
import { ComponentBase } from "resub";
import { ILeadState, LeadViewModel } from "../../../view-model/LeadViewModel";
import { DependencyInjector } from "../../../dependency-injector/DependencyInjector";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./Leads.css";
import "../../../CSS/Main.css";
import ImageAssets from "../../../assets/ImageAssets";
import { isNullOrUndefined } from "util";
import { TablePagination, Select, MenuItem } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class Leads extends ComponentBase<any, ILeadState> {
  private viewModel: LeadViewModel;

  constructor(props: any) {
    super(props);

    this.viewModel = DependencyInjector.default().provideLeadViewModel();
  }

  componentDidMount(): void {
    //condition for applying FIlter........
    if (this.props.history.location.state === null) {
      // this.viewModel.load();
      this.viewModel.set("pageIndex", 0);
      this.viewModel.set("filterKey", "All");
      this.viewModel.set("leadfilterKey", "All");
      console.log("in");
      this.viewModel.load();
    } else if (this.props.history.location.state === undefined) {
      this.viewModel.load();
      console.log("link");
    } else if (
      this.props.history.location.state.toDate === undefined &&
      this.props.history.location.state.fromDate === undefined &&
      this.props.history.location.state.filter !== undefined
    ) {
      this.viewModel.set("pageIndex", 0);
      this.viewModel.set("filterKey", this.props.history.location.state.filter);
      this.viewModel.set(
        "leadfilterKey",
        this.props.history.location.state.leadfilter
      );
      console.log(this.props.history.location.state.filter);
      this.viewModel.load();
    } else if (
      this.props.history.location.state.toDate &&
      this.props.history.location.state.fromDate !== undefined
    ) {
      console.log("else", this.props.history.location.state);
      this.viewModel.set(
        "fromDate",
        this.props.history.location.state.fromDate
      );
      this.viewModel.set(
        "fromDateString",
        moment(this.props.history.location.state.fromDate).format("YYYY-MM-DD")
      );
      //this.viewModel.load();

      this.viewModel.set("toDate", this.props.history.location.state.toDate);
      this.viewModel.set(
        "toDateString",
        moment(this.props.history.location.state.toDate).format("YYYY-MM-DD")
      );
      // this.viewModel.load();
      if (this.props.history.location.state.filter !== undefined) {
        this.viewModel.set("pageIndex", 0);
        this.viewModel.set(
          "filterKey",
          this.props.history.location.state.filter
        );
        this.viewModel.set(
          "leadfilterKey",
          this.props.history.location.state.leadfilter
        );
        console.log(this.props.history.location.state.filter);
        this.viewModel.load();
      } else {
        this.viewModel.load();
        console.log("date");
      }
    } else {
      this.viewModel.load();
      // this.viewModel.set("pageIndex", 0);
      // this.viewModel.set("filterKey", "All");
      console.log("out");
    }
    //this.viewModel.load();
  }

  sortInfo = (event: any) => {
    const element = event.target.id;
    this.viewModel.set("sortBy", element);
    this.viewModel.set("orderBy", !this.state.orderBy);
    this.viewModel.load();
  };
  handleFilter = async (event: any) => {
    this.viewModel.set("isLoading", true);
    this.viewModel.set("pageIndex", 0);
    this.viewModel.set("filterKey", event.target.value);
    await this.viewModel.load();
    this.viewModel.set("isLoading", false);
  };

  handleleadFilter = async (event: any) => {
    this.viewModel.set("isLoading", true);
    this.viewModel.set("pageIndex", 0);
    this.viewModel.set("leadfilterKey", event.target.value);
    await this.viewModel.load();
    this.viewModel.set("isLoading", false);
  };
  /* */
  handleFromDateChange = (date: Date) => {
    this.viewModel.set("fromDate", date);
    this.viewModel.set("fromDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };
  handleToDateChange = (date: Date) => {
    this.viewModel.set("toDate", date);
    this.viewModel.set("toDateString", moment(date).format("YYYY-MM-DD"));
    this.viewModel.load();
  };

  clearFromTo = () => {
    this.viewModel.set("fromDate", undefined);
    this.viewModel.set("fromDateString", "");
    this.viewModel.set("toDate", undefined);
    this.viewModel.set("toDateString", "");
    this.viewModel.load();
  };
  render() {
    console.log(this.state.leads);
    // console.log(this.state.isLoading);

    const tableBodyinfo: any = [];

    if (!isNullOrUndefined(this.state.leads) && this.state.leads.length > 0) {
      let index = 0;
      for (let lead of this.state.leads) {
        index++;
        tableBodyinfo.push(
          <TableRow key={index}>
            <TableCell className="UserName" align="center">
              {lead.name}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.phoneNumber}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.project_name__c}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.city}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.createdDate}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.leadStage}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead.leadStatus ? lead.leadStatus : "NA"}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead?.dateAndTimeOfMeetingScheduled}
            </TableCell>
            <TableCell className="userDetail" align="center">
              {lead?.call_stage__c}
            </TableCell>
          </TableRow>
        );
      }
    }
    console.log(this.state.leads);
    return (
      <div>
        {/* <div style={{ width: "100%", height: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div> */}

        <div className=" row pt-3 justify">
          <div className="">
            <button
              className="submitLeadLop"
              onClick={() => {
                this.props.history.push("/Home/submitLeads");
              }}
            >
              Submit your Lead
            </button>
          </div>
          <div className="filter">
            <div className="d-flex inherit">
              <div className=" ">
                <div className="d-flex col-sm-4">
                  <p className="pr-2 dateText">From</p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="dateInput  ptm-2 ptm-5"
                    selected={this.state.fromDate}
                    onChange={this.handleFromDateChange}
                  />
                </div>
              </div>
              <div className="col-sm-4 col-md-0 col-lg-0 col-xl-0 ">
                <div className="d-flex">
                  <p className="pr-2 pr-4 dateText">To</p>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    className="dateInput ptm-2 ptm-5"
                    selected={this.state.toDate}
                    onChange={this.handleToDateChange}
                    minDate={this.state.fromDate}
                  />
                </div>
              </div>
              <div className="ptm-4 col-sm-4 col-md-0 col-lg-0 col-xl-0 pl-3 pl-sm-3 pl-md-4 pl-lg-4 pl-xl-4 pt-lg-0 pt-xl-0">
                <button
                  className="dashboard-all-time-button "
                  onClick={this.clearFromTo}
                >
                  All Time
                </button>
              </div>
            </div>

            <div className="d-flex leadDatePicker justify-content-lg-start justify-content-xl-end justify-content-md-start justify-content-sm-start justify-content-start filter1">
              <div className="d-flex  pt-2  dropdown leadDatePicker">
                <p className="lopSort pr-1">Lead Stage: </p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.filterKey}
                  onChange={this.handleFilter}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="New">New</MenuItem>
                  <MenuItem value="Connected">Connected</MenuItem>
                  <MenuItem value="Not Connected">Not Connected</MenuItem>
                  <MenuItem value="Meeting Scheduled">
                    Meeting Scheduled
                  </MenuItem>
                  <MenuItem value="Meeting Confirmed">
                    Meeting Confirmed
                  </MenuItem>
                  <MenuItem value="Converted">Converted</MenuItem>
                </Select>
              </div>
            </div>
            <div className="d-flex leadDatePicker justify-content-lg-start justify-content-xl-end justify-content-md-start justify-content-sm-start justify-content-start filter1">
              <div className="d-flex  pt-2  dropdown leadDatePicker">
                <p className="lopSort pr-1">Lead Status: </p>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.leadfilterKey}
                  onChange={this.handleleadFilter}
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Undailed">Undailed</MenuItem>
                  <MenuItem value="Recontacted">Recontacted</MenuItem>
                  <MenuItem value="Not Connected">Not Connected</MenuItem>
                  <MenuItem value="Followup">Followup</MenuItem>
                  <MenuItem value="Prospect">Prospect</MenuItem>
                  <MenuItem value="Junk">Junk</MenuItem>
                  <MenuItem value="CC Error">CC Error</MenuItem>
                  <MenuItem value="Lost Prospect">Lost Prospect</MenuItem>
                  <MenuItem value="Meeting Scheduled">
                    Meeting Scheduled
                  </MenuItem>
                  <MenuItem value="Meeting Rescheduled">
                    Meeting Rescheduled
                  </MenuItem>
                  <MenuItem value="Meeting Done">Meeting Done</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <Paper>
            <TableContainer className="paper" component={Paper}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="name"
                      onClick={this.sortInfo}
                    >
                      Lead Name{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="phoneNumber"
                      onClick={this.sortInfo}
                    >
                      Mobile number{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="phoneNumber"
                      onClick={this.sortInfo}
                    >
                      Project Name{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="city"
                      onClick={this.sortInfo}
                    >
                      City{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="createdDate"
                      onClick={this.sortInfo}
                    >
                      Created Date{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="leadStage"
                      onClick={this.sortInfo}
                    >
                      Lead Stage{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="leadStatus"
                      onClick={this.sortInfo}
                    >
                      Lead Status{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="leadStatus"
                      onClick={this.sortInfo}
                    >
                      Meeting Scheduled
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                    <TableCell
                      className="userHead table-cell-single-line"
                      variant="head"
                      align="center"
                      id="email"
                      onClick={this.sortInfo}
                    >
                      Reason{" "}
                      <img src={ImageAssets.dc_Sort_Image} alt="sort"></img>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBodyinfo}</TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              labelRowsPerPage="Rows/page"
              count={this.state.totalCount}
              rowsPerPage={this.state.pageSize}
              page={this.state.pageIndex}
              onChangePage={(event, pageIndex) => {
                this.viewModel.set("pageIndex", pageIndex);
                this.viewModel.load();
              }}
              onChangeRowsPerPage={(event) => {
                console.log("pageSize", event.target.value);
                this.viewModel.set("pageSize", event.target.value);
                this.viewModel.load();
              }}
            />
          </Paper>
        </div>
      </div>
    );
  }

  protected _buildState() {
    return this.viewModel.getState();
  }
}
